/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Vamos a empezar la primera actividad con el ajuste de uno de los controladores más sencillos que existe, el controlador proporcional o simplemente P. Este controlador lo hemos visto de pasada en capítulos anteriores, se trata del controlador estático, una simple constante multiplicando a la señal de error y su salida es la señal de control."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/dcdb2fc3ae33bfcbb7329791f732d607/8c557/Realimentaci%C3%B3n.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVQY042Q0W6GIAyFff9nJHohJoYWRJGGIthlf/dvLrvZd9UcenpKh2VZiCildD7IOdObv0rOWcUhxigi933Lg+u6+EUppfcuIr13ftNa07Zh2zYRaa313mutzCwiiHgcBxEhYs5ZRI7jCCEQUYxRLfd9f5qJyDk3TdOyLADgnDPGAIC1dp5n7z0ijuMYQpjnOcYIAMaYUspXsoiEEKy1WmsgM4cQvpNjjMx8nicApJR+1tZ/ruuaUiIi7z0AIKJzTvff910VRPTe/zLrwWqt5UV9oGdj5qei/YNzrrWmb9e/0SkfbsTL1rtrxj4AAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Esquema de Realimentación.\"\n        title=\"Esquema de Realimentación\"\n        src=\"/static/dcdb2fc3ae33bfcbb7329791f732d607/f058b/Realimentaci%C3%B3n.png\"\n        srcset=\"/static/dcdb2fc3ae33bfcbb7329791f732d607/c26ae/Realimentaci%C3%B3n.png 158w,\n/static/dcdb2fc3ae33bfcbb7329791f732d607/6bdcf/Realimentaci%C3%B3n.png 315w,\n/static/dcdb2fc3ae33bfcbb7329791f732d607/f058b/Realimentaci%C3%B3n.png 630w,\n/static/dcdb2fc3ae33bfcbb7329791f732d607/8c557/Realimentaci%C3%B3n.png 700w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Este controlador debido a su sencillez, sólo nos ofrece un grado de libertad, modificar el valor de la constante (kp) y por lo tanto tendremos algunas limitaciones que veremos en la práctica."), "\n", React.createElement(_components.p, null, "Nuestro objetivo va a ser conseguir que la salida del sistema siga a la referencia, que va a ser una señal de escalón unitario, esto es que en el instante 0 pasa de 0 a 1 inmediatamente, por eso tiene una forma de escalón."), "\n", React.createElement(_components.p, null, "¿Cómo quedaría si no utilizáramos ningún control y no cerráramos el bucle? Directamente aplicando la referencia en escalón al bucle obtendríamos esto:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/8f07a62572f8a66917f4ac67975cd73c/b06ae/bucleabierto_sin-control.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 77.21518987341771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABTElEQVQoz5WS3W7DIAyF+/6v2ItKbQMkUJuMBJdfTw1aklWZqp0LdDjG/kDixIuklMMwAMAwDMYYAOgXGWOEkFrrfUlr7Zxj5lOtlZkBIMbIzG27N8xb0sIYIwC8mlvhLu4jjZRpjvMcPSXv4zwHb2cPboYv/xi9GWczenCkHuOjNbdhiNjIpdRl5Wmq3nMITFRLeScj4kaWUoYQlraSUp2m+nyW5WittbS2soiZiQgOyTG+gIePPyCXUohIKdXIzpWykHLegJ/JKcUQ+Pk8AP5JbgWlFFHwnkvJjbQHfiBbi87FlPhn+n/Ifa8QQ628cnLOzTfgmhyQtYZxjL8/1rvW0kZu0e2muk4hohBCa42IXddpra21Qoi+7xFRStmMUspau117nZpSaj6lRETTNBFRu21etDen6/V6uVyMMefzuR1alXMOIbyFe30Df2tsKcIg/foAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Sin control, sistema en bucle abierto\"\n        title=\"Sin control, sistema en bucle abierto\"\n        src=\"/static/8f07a62572f8a66917f4ac67975cd73c/b06ae/bucleabierto_sin-control.png\"\n        srcset=\"/static/8f07a62572f8a66917f4ac67975cd73c/c26ae/bucleabierto_sin-control.png 158w,\n/static/8f07a62572f8a66917f4ac67975cd73c/6bdcf/bucleabierto_sin-control.png 315w,\n/static/8f07a62572f8a66917f4ac67975cd73c/b06ae/bucleabierto_sin-control.png 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "La línea azul nos muestra la evolución del sistema y la verde el valor al que tiende en el infinito. Como vemos el sistema llega a 10 en lugar de a 1 y es bastante lento, unos 50 segundos, así que vamos a mejorar todo esto con nuestro control P."), "\n", React.createElement(_components.p, null, "Para probar distintos valores de kp en el bucle de una forma sencilla podéis descargaros ", React.createElement(_components.a, {
    href: "http://entramado.net/cursocontrol/ControlLearningToolbox/controlP.m"
  }, "este script"), " que he hecho para Octave, el funcionamiento es el siguiente: tenéis un sistema por defecto dentro del script y todo montado para simular. Nos situamos en Octave dentro de la carpeta donde hemos guardado el script y llamamos a la función del controlP:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlP</span><span class=\"token punctuation\">(</span>kp<span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Donde kp es la constante proporcional que queramos utilizar. Podemos probar por ejemplo:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlP</span><span class=\"token punctuation\">(</span><span class=\"token number\">1</span><span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y nos mostrará la siguiente imagen:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/b49b67a6c68e2e928d8aa3c56733fc17/b06ae/controlP1.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 77.21518987341771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZUlEQVQoz41S2a7jIAzt/39i31pVZV/SQDAB7FHqitzRVLrjJyN8Fi8XIhpjKKW01t57rbW11nsvpdRaO+eklMaYn1/GmHVdiehC71BK1VqZCBGJkBPEv5IxBhEBgHPuBBtjJpgIe8fev7CMgURUaw0hnGBr7QSXgtuGKY1tO1iYjuiAIw4Ge+8P8JuepJYhh9JLTKuNeWs51bRs2cbsXimmHFMOa3JLfpVN2mid/yjv+/58PmvdiSil0fthctpubQAgAJYyWkPu+aPceweAGOO+74iUM3Ij7OifBFns7Lm1JoRorQIQwNch4xiHlzntjzJTan2sCoA6m8ZfVnWCeVWlHOBZ/BXMLZzTZmVj9OtVeyc2+avyzz2jUjqE88L+V5nfj4d6PFSMQYjPJQshrLUxxnnbQghOlFLLspw9z31MekQEgHVdAYC/+nvcXMPJ5X6/3243Y8z1euULndF7r7Vy3df4A0Mpa1U23oNmAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Control Proporcionas con ganancia 1.\"\n        title=\"Control Proporcional con ganancia 1.\"\n        src=\"/static/b49b67a6c68e2e928d8aa3c56733fc17/b06ae/controlP1.png\"\n        srcset=\"/static/b49b67a6c68e2e928d8aa3c56733fc17/c26ae/controlP1.png 158w,\n/static/b49b67a6c68e2e928d8aa3c56733fc17/6bdcf/controlP1.png 315w,\n/static/b49b67a6c68e2e928d8aa3c56733fc17/b06ae/controlP1.png 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Vemos que el control ha mejorado bastante, es como 5 veces más rápido y a pesar de que aún no llega a 1 exactamente ya se acerca bastante más y no se pasa demasiado en el trayecto (sobreoscilación). Ahora podemos ir buscando una kp que haga que el sistema funcione más a nuestro gusto."), "\n", React.createElement(_components.p, null, "El objetivo de la actividad es experimentar con el control proporcional y buscar una buena respuesta, podéis escribir un post con la experiencia en vuestro blog y si no dejarla en comentarios o mandármela por ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/contacto/"
  }, "correo"), " con vuestro nombre/alias y yo la publicaré en los comentarios."), "\n", React.createElement(_components.p, null, "Suerte."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
